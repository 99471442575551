<template>
    <div
        v-if="item"
        :key="`schedule-lesson-detail-${componentKey}`"
    >
        <div v-if="viewMode">
            <lesson-preview
                :key="`lesson-preview-${componentKey}`"
                :item="item.lesson"
            />
            <v-divider></v-divider>
            <div class="d-flex align-center mt-4">
                <v-btn
                    depressed
                    text
                    color="success"
                    @click="isLessonForm = true"
                    class="ml-2"
                >
                    <w-icon
                        value="EDIT"
                        size="20"
                        left
                    />
                    {{ $trans('Edit') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    depressed
                    text
                    color="primary"
                >
                    <w-icon
                        value="VIEW"
                        size="20"
                        left
                    />
                    {{ $trans('Details') }}
                </v-btn>
            </div>
        </div>
        <div v-else>
            <schedule-lesson-form
                :key="`lesson-form-${componentKey}`"
                :src="item"
                @cancel="onClose"
                @submit="onSubmit"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import LessonPreview from '@/apps/school/components/Lesson/LessonPreview'
import ScheduleLessonForm from '@/apps/school/components/Schedule/ScheduleLessonForm'
import ScheduleLessonHandler from '@/apps/school/components/Schedule/Services/ScheduleLessonHandler'

export default {
    name: 'ScheduleLessonDetails',
    components: {
        ScheduleLessonForm,
        LessonPreview
    },
    props: {
        item: {
            type: ScheduleLessonHandler,
            validator: function (value) {
                return value instanceof ScheduleLessonHandler
            }
        }
    },
    computed: {
        uuid() {
            return _.get(this.item, 'lesson.uuid', null)
        },
        groupUuid() {
            return _.get(this.item, 'group.uuid')
        },
        day() {
            return _.get(this.item, 'day.value')
        },
        call() {
            return _.get(this.item, 'call.begin')
        },
        viewMode() {
            return this.uuid && !this.isLessonForm
        }
    },
    watch: {
        uuid() {
            this.componentKey++
            this.isLessonForm = false
        },
        groupUuid() {
            this.componentKey++
            this.isLessonForm = false
        },
        day() {
            this.componentKey++
            this.isLessonForm = false
        },
        call() {
            this.componentKey++
            this.isLessonForm = false
        }
    },
    data() {
        return {
            componentKey: 0,
            isLessonForm: false
        }
    },
    methods: {
        onSubmit(item, action, origin) {
            this.isLessonForm = false
            this.$emit('submit', item, action, origin)
            this.$emit('close')
        },
        onClose() {
            if (this.uuid) {
                this.isLessonForm = false
            } else {
                this.$emit('close')
            }
        }
    }
}
</script>

<style lang="scss">

</style>
