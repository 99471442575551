<template>
    <v-btn
        icon
        @click="$emit('click')"
    >
        <w-icon :value="icon"/>
    </v-btn>
</template>

<script>
export default {
    name: 'ScheduleOffsetBtn',
    props: [ 'icon' ]
}
</script>

<style lang="scss">

</style>
