<template>
    <div class="schedule-groups">
        <div v-if="!isMobile" class="d-flex">
            <v-card
                tile
                :class="itemClass"
                width="7%"
                :height="height"
                :color="itemColor"
            >
                <w-icon value="CLOCK"></w-icon>
            </v-card>
            <v-card
                v-for="group in items"
                :key="group.uuid"
                :width="(93 / items.length) + '%'"
                tile
                :class="itemClass"
                :height="height"
                :color="itemColor"
                class="schedule-group"
            >
                <span class="text-truncate">{{ group.title }}</span>
            </v-card>
            <v-card
                width="12"
                tile
                :height="height"
                :color="itemColor"
            >&nbsp;</v-card>
        </div>
        <v-sheet v-else class="d-block px-4">
            <v-select
                v-model="selectedGroup"
                dense
                hide-details
                :height="height"
                :items="items"
                item-text="title"
                item-value="uuid"
            />
        </v-sheet>
    </div>
</template>

<script>
import mobileBreakpointCheckerMixin from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'ScheduleGroups',
    mixins: [ mobileBreakpointCheckerMixin ],
    props: {
        value: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            selectedGroup: null,
            height: 30,
            itemClass: 'd-flex align-center justify-center',
            itemColor: undefined
        }
    },
    watch: {
        value(uuid) {
            this.selectedGroup = uuid
        },
        selectedGroup(uuid) {
            this.onInput()
        }
    },
    methods: {
        onInput() {
            this.$emit('input', this.selectedGroup)
        },
        onSelectGroup() {
            this.onInput()
            this.$emit('click', this.selectedGroup)
        }
    }
}
</script>

<style lang="scss" scoped>
    .schedule-groups {
           .schedule-group {
               border-left: rgba(231, 227, 252, 0.68) solid 1px;
           }
    }
</style>
