<template>
    <v-card flat tile>
        <v-img
            v-if="cover"
            height="160"
            width="100%"
            src="@/assets/images/pages/developerMeetup.png"
            class="mb-5"
        />
        <v-card-text class="d-flex align-center pa-0">
            <v-avatar
                size="54"
                rounded
                color="primary"
                class="v-avatar-light-bg primary--text mt-1"
            >
                <div>
                    <span class="font-weight-medium">{{ begin | dateFormat('MMM') }}</span>
                    <p class="text-xl primary--text font-weight-bold mb-0">{{ begin | dateFormat('DD') }}</p>
                </div>
            </v-avatar>

            <div class="ms-3">
                <h4 class="text-base font-weight-semibold">{{ course }}</h4>
                <p class="text-xs mb-0">{{ item.lesson_type.title }}</p>
            </div>
        </v-card-text>
        <v-card-text class="my-4 pa-0">
            <v-divider></v-divider>
        </v-card-text>
        <v-card-text>
            <w-options-list
                :items="details"
            />
            <div class="my-4 pa-0">
                <v-divider></v-divider>
            </div>
            <member-full-name
                :item="teacher"
                :subtitle="department"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import MemberFullName from '@/components/Member/MemberFullName'

export default {
    name: 'LessonPreview',
    components: { MemberFullName },
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    computed: {
        cover() {
            return _.get(this.item, 'cover', null)
        },
        course() {
            return _.get(this.item, 'course.title', 'unknown')
        },
        title() {
            return _.get(this.item, 'title', 'unknown')
        },
        begin() {
            return _.get(this.item, 'begin', null)
        },
        end() {
            return _.get(this.item, 'end', null)
        },
        location() {
            return {
                title: _.get(this.item, 'location.title', null),
                subtitle: _.get(this.item, 'location.description', null)
            }
        },
        department() {
            return _.get(this.item, 'course.department.title', 'unknown')
        },
        teacher() {
            return _.get(this.item, 'teacher')
        },
        locale() {
            return this.$store.getters.locale
        },
        details() {
            moment.updateLocale(this.locale)

            return [
                {
                    icon: 'CLOCK',
                    title: `${_.upperFirst(moment(this.begin).format('dddd, DD MMMM, HH:mm'))} - ${moment(this.end).format('HH:mm')}`,
                    subtitle: moment(this.begin).fromNow()
                },
                {
                    icon: 'LOCATION',
                    title: this.location.title,
                    subtitle: this.location.subtitle
                }
            ]
        }
    },
    data() {
        return {}
    }
}
</script>
