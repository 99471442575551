<template>
    <content-wrapper
        :breadcrumbs="breadcrumbs"
    >
        <v-sheet
            v-if="level"
            outlined
            class="schedule-wrapper"
        >
            <v-sheet
                dark
                color="primary"
                class="pt-4"
            >
                <div
                    class="d-flex justify-space-between align-center px-4"
                >
                    <schedule-offset-btn icon="PREV_DOUBLE" @click="prevWeek" />
                    <schedule-title
                        :title="level.title"
                        :begin="service.begin(offset)"
                        :end="service.end(offset)"
                    />
                    <schedule-offset-btn icon="NEXT_DOUBLE" @click="nextWeek" />
                </div>
                <div
                    class="mt-4"
                >
                    <schedule-groups
                        v-model="activeTab"
                        :items="groups"
                    />
                </div>
            </v-sheet>
            <v-card
                :loading="loading"
                :disabled="loading"
                tile
                class="schedule-grid"
            >
                <v-sheet
                    v-for="row in service.schedule(offset, scheduleGroups, callSchedule, onActiveLesson)"
                    v-bind="row.props"
                    :key="row.props.key"
                >
                    <v-card
                        v-for="col in row.cols"
                        v-bind="col.props"
                        :key="col.props.key"
                        v-on="col.on"
                    >
                        <component :is="col.component" :item="col.data" />
                    </v-card>
                </v-sheet>
            </v-card>
        </v-sheet>
        <w-aside-drawer
            v-model="lessonDialog"
            :title="`${$trans('Group')}: ${activeLessonGroup}`"
        >
            <div class="px-4 py-3">
                <schedule-lesson-details
                    :item="activeLesson"
                    @submit="onSubmit"
                    @close="closeLessonDialog"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
/* eslint-disable */
import _ from 'lodash'
import ContentWrapper from '@/components/ContentWrapper'
import { getSchedule } from '@apps/school/api/schedule'
import ScheduleDataHandler from '@apps/school/components/Schedule/Services/ScheduleDataHandler'
import ScheduleLessonDetails from '@apps/school/components/Schedule/ScheduleLessonDetails'
import mobileBreakpointCheckerMixin from '@/mixins/mobileBreakpointChecker'
import ScheduleTitle from '@apps/school/components/Schedule/ScheduleTitle'
import ScheduleOffsetBtn from '@apps/school/components/Schedule/ScheduleOffsetBtn'
import ScheduleGroups from '@apps/school/components/Schedule/ScheduleGroups'
import { storeLesson, deleteLesson, updateLesson } from '@apps/school/api/lesson'
import ConfirmationSets from '@/utils/ConfirmationSets'
import Debugger from '@/utils/Debugger'

export default {
    name: 'ScheduleLevel',
    components: {
        ScheduleGroups,
        ScheduleOffsetBtn,
        ScheduleTitle,
        ScheduleLessonDetails,
        ScheduleColLesson: () => import('@apps/school/components/Schedule/ScheduleColLesson'),
        ScheduleColCall: () => import('@apps/school/components/Schedule/ScheduleColCall'),
        ScheduleColDay: () => import('@apps/school/components/Schedule/ScheduleColDay'),
        ContentWrapper
    },
    mixins: [ mobileBreakpointCheckerMixin ],
    computed: {
        levelUuid() {
            return this.$route.params.uuid
        },
        level() {
            return this.$store.getters['school/level'](this.levelUuid)
        },
        groups() {
            return this.level.groups
        },
        activeLessonGroup() {
            return _.get(this.activeLesson, 'group.title', null)
        },
        scheduleGroups() {
            if (this.activeTab === null) {
                return this.level.groups
            } else {
                return _.filter(this.level.groups, group => group.uuid === this.activeTab)
            }
        },
        callSchedule() {
            return this.$store.getters['school/callSchedule']
        },
        title() {
            return this.level.title
        },
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Library'),
                    to: { name: 'manage' }
                },
                {
                    text: this.$trans('Schedule'),
                    to: { name: 'manage.school.schedule' }
                },
                {
                    text: this.level ? this.level.title : null
                }
            ]
        }
    },
    watch: {
        isMobile(flag) {
            if (flag) {
                this.activeTab = _.get(_.first(this.groups), 'uuid', null)
            } else {
                this.activeTab = null
            }
        }
    },
    data() {
        return {
            service: new ScheduleDataHandler(),
            offset: 0,
            activeTab: null,
            loading: false,
            activeLesson: null,
            lessonDialog: false,
        }
    },
    mounted() {
        this.fetchSchedule()
    },
    methods: {
        fetchSchedule() {
            this.loading = true

            const payload = {
                level: this.levelUuid,
                begin: this.service.begin(this.offset),
                end: this.service.end(this.offset)
            }

            getSchedule(payload)
                .then(response => {
                    this.service.setCoursesData(response.data.courses)
                    this.service.setLocationsData(response.data.locations)
                    this.service.setScheduleData(response.data.data)
                    this.loading = false
                })
            .catch(error => {
                Debugger.log(error)
            })
        },
        nextWeek() {
            this.offset++
            this.fetchSchedule()
        },
        prevWeek() {
            this.offset--
            this.fetchSchedule()
        },
        onScheduleGroup(uuid) {
            this.activeTab = uuid
        },
        onActiveLesson(item) {
            this.activeLesson = item
            this.lessonDialog = true
        },
        async onSubmit(item, action, origin) {
            this.loading = true

            const handlers = {
                create: async item => {
                    const response = await storeLesson(item)
                    // this.service.setScheduleItem(response.data.data)
                    this.fetchSchedule()
                },
                update: async (item, origin) => {
                    const response = await updateLesson(item.uuid, item)
                    // await this.service.unsetScheduleItem(origin)
                    // await this.service.setScheduleItem(response.data.data)
                    this.fetchSchedule()
                },
                delete: async (item, origin) => {
                    const title = `${this.$trans('Delete lesson')}?`

                    try {
                        const isAgree = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({title}))

                        if(isAgree) {
                            await deleteLesson(item.uuid)
                            // this.service.unsetScheduleItem(origin)
                            this.fetchSchedule()
                        }
                    } catch (err) {}
                }
            }

            try {

                await handlers[action](item, origin)

                this.loading = false
                this.lessonDialog = false

            } catch (err) {
                this.loading = false
                Debugger.log(err)
            }
        },
        closeLessonDialog() {
            this.lessonDialog = false
        }
    }
}
</script>

<style lang="scss">
    $border-color: rgba(94,86,105,.14);
    $border: thin solid $border-color !important;
    $primary-color-plain: #f2ebff;
    $primary-color-base: var(--v-primary-base);

    .schedule-wrapper {
        overflow: hidden;
        border-radius: 6px!important;

        .schedule-grid {
            height: calc(100vh - 250px);
            overflow-y: scroll;

            /* Works on Firefox */
            & {
                scrollbar-width: 12px;
                scrollbar-color: $primary-color-base $primary-color-plain;
            }

            /* Works on Chrome, Edge, and Safari */
            &::-webkit-scrollbar {
                width: 12px;
            }

            &::-webkit-scrollbar-track {
                background: $primary-color-plain;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $primary-color-base;

                border-radius: 20px;
            }
        }

        .schedule-row--day {
            background-color: $primary-color-plain !important;

            * {
                color: $primary-color-base !important;
            }
        }

        .schedule-row {
            border-top: $border;
        }

        .schedule-col {
            border: none;
        }

        .schedule-row--lesson {
            .schedule-col {

                & + .schedule-col {
                    border-left: $border;
                }
            }
        }
    }
</style>
