<template>
    <div class="text-center" style="flex: 1">
        <div v-if="title" class="subtitle font-weight-semibold">{{ $trans('Schedule') }} {{ title }}</div>
        <div class="d-flex align-center justify-center mt-2">
            <w-icon value="CALENDAR" left />
            <span>{{ begin | dateFormat('DD-MM-YYYY') }}</span>&nbsp;-&nbsp; <span>{{ end | dateFormat('DD-MM-YYYY')}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleTitle',
    props: [ 'title', 'begin', 'end' ]
}
</script>

<style lang="scss">

</style>
