<template>
    <div
        class="schedule-lesson-form"
        :class="isCreating ? 'mt-4' : ''"
    >
        <v-btn
            v-if="!isCreating"
            icon
            class="mb-3"
            @click="onCancel"
        >
            <w-icon value="COM_BACK"/>
        </v-btn>
        <v-form
            v-model="isValid"
            ref="scheduleLessonForm"

        >
            <v-card
                v-if="isCreating"
                outlined
                class="pt-4 pa-3"
            >
                <v-autocomplete
                    v-model="formData.course_uuid"
                    ref="courseSelect"
                    dense
                    :label="$trans('Course')"
                    item-text="title"
                    item-value="uuid"
                    :items="src.courses"
                    :prepend-icon="icons.COURSE"
                    :rules="formRules.course_uuid"
                    @keydown.esc="onCancel"
                    @change="onChangeCourse"
                >
                    <template #item="{ item, on, attr }">
                        <v-list-item dense v-on="on" v-bind="attr">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title | truncate }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <v-select
                    v-model="formData.lesson_type_uuid"
                    :disabled="formData.course_uuid === null"
                    :label="$trans('Lesson type')"
                    :prepend-icon="icons.LESSON"
                    :rules="formRules.lesson_type_uuid"
                    :items="lessonTypes"
                    item-value="uuid"
                    item-text="title"
                    @change="onChangeLessonType"
                >
                    <template #selection="{ item }">
                        <div class="d-flex align-center">
                            <span>{{ item.title }}</span>
                            <span class="caption">&nbsp; ({{ item.teacher | fullName('short') }})</span>
                        </div>
                    </template>
                    <template #item="{ item, on, attr }">
                        <v-list-item v-on="on" v-bind="attr" dense>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.teacher | fullName('short') }}</v-list-item-subtitle>
                        </v-list-item>
                    </template>
                </v-select>
            </v-card>
            <v-card v-else outlined class="pa-4">
                <v-card-text class="pa-0">
                    <div class="h4 font-weight-bold">
                        <span>{{ course.title }}</span> <br>
                        <span class="caption">{{
                                src.lesson.lesson_type.title
                            }} ({{ src.lesson.teacher | fullName('short') }})</span>
                    </div>
                </v-card-text>
            </v-card>
            <v-card
                outlined
                class="px-3 mt-4"
            >
                <v-radio-group
                    v-model="lessonMode"
                    row
                    @change="onChangeLessonMode"
                >
                    <v-radio
                        label="Offline"
                        color="primary"
                        value="offline"
                    ></v-radio>
                    <v-radio
                        label="Online"
                        color="success"
                        value="online"
                    ></v-radio>
                </v-radio-group>
                <v-autocomplete
                    v-if="lessonMode === 'offline'"
                    v-model="formData.location_uuid"
                    outlined
                    dense
                    :label="$trans('Location')"
                    :items="src.locations"
                    item-text="title"
                    item-value="uuid"
                    :prepend-icon="icons.LOCATION"
                    :rules="formRules.location_uuid"
                >
                    <template #item="{ item, on, attrs }">
                        <v-list-item dense v-bind="attrs" v-on="on" style="height: 2.5rem">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                                <v-list-item-subtitle
                                    v-if="item.description"
                                    class="caption text--secondary"
                                >
                                    {{ item.description }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-card>
            <v-card
                outlined
                class="px-4 pb-4 mt-4"
            >
                <v-card-text class="pa-0">
                    <v-select
                        v-model="selectedDay"
                        :prepend-icon="icons.CALENDAR"
                        :items="daysOfWeek"
                        hide-details
                        @change="onDaySelected"
                    />
                    <v-select
                        v-model="selectedCall"
                        :prepend-icon="icons.CLOCK"
                        :items="callSchedule"
                        item-text="text"
                        item-value="value"
                        hide-details
                        @change="onCallSelected"
                    />
                </v-card-text>
            </v-card>
            <div class="d-flex align-center mt-4">
                <v-btn
                    v-if="!isCreating"
                    text
                    color="error"
                    class="mr-1"
                    @click="onSubmit('delete')"
                >
                    {{ $trans('Delete') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="!isValid"
                    depressed
                    color="primary"
                    @click="onSubmit(isCreating ? 'create' : 'update')"
                >
                    {{ isCreating ? $trans('Add') : $trans('Update') }}
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { COURSE, LOCATION, CALENDAR, CLOCK, LESSON } from '@/widgets/components/WIcon/icons'
import { required } from '@/utils/validation'
import ScheduleLessonHandler from '@/apps/school/components/Schedule/Services/ScheduleLessonHandler'

export default {
    name: 'ScheduleLessonForm',
    props: {
        value: {
            type: Object,
            default: function () {
                return {}
            }
        },
        src: {
            type: ScheduleLessonHandler,
            default: function () {
                return new ScheduleLessonHandler({})
            },
            validator: function (value) {
                return value instanceof ScheduleLessonHandler
            }
        }
    },
    filters: {
        truncate(text) {
            return _.truncate(text)
        }
    },
    computed: {
        course() {
            return _.find(_.get(this.src, 'courses'), [ 'uuid', _.get(this.formData, 'course_uuid') ])
        },
        daysOfWeek() {
            const result = []

            this.src.weekDays.forEach(item => {
                result.push({
                    text: _.upperFirst(moment(item.value)
                        .format('dddd, DD MMMM, YYYY')),
                    value: item.value
                })
            })

            return result
        },
        callSchedule() {
            const result = []

            this.src.callSchedule.forEach(item => {
                result.push({
                    text: `${item.label}, ${item.begin} - ${item.end}`,
                    value: item.begin
                })
            })

            return result
        },
        lessonTypes() {
            if (!this.formData.course_uuid) {
                return []
            }

            const selectedCourse = _.find(this.src.courses, [ 'uuid', this.formData.course_uuid ])
            return _.filter(selectedCourse.lesson_types, o => _.includes(o.groups, this.src.group.uuid))
        }
    },
    async mounted() {
        this.formData = Object.assign({}, this.src.formData())
        this.isCreating = !this.formData.uuid
        this.setDates()
        this.originalData = Object.assign({}, this.src.lesson)

        setTimeout(() => {
            if (this.isCreating) {
                this.$refs.courseSelect.focus()
            }
        }, 300)
    },
    data() {
        return {
            componentKey: 0,
            isCreating: true,
            isValid: true,
            icons: {
                COURSE,
                LOCATION,
                CALENDAR,
                CLOCK,
                LESSON
            },
            selectedDay: this.src.getDay('value'),
            selectedCall: this.src.getCall('begin'),
            formRules: {
                course_uuid: [
                    required
                ],
                location_uuid: [
                    required
                ],
                lesson_type_uuid: [
                    required
                ]
            },
            originalData: {},
            lessonMode: 'offline',
            formData: {
                uuid: null,
                begin: null,
                end: null,
                course_uuid: null,
                location_uuid: null,
                lesson_type_uuid: null,
                capacity_uuid: null,
                teacher_uuid: null
            }
        }
    },
    methods: {
        setDates() {
            this.formData.begin = this.getDate('begin')
            this.formData.end = this.getDate('end')
        },
        getDate(target) {
            const call = _.get(_.find(this.src.callSchedule, [ 'begin', this.selectedCall ]), target)
            const day = _.get(_.find(this.src.weekDays, [ 'value', this.selectedDay ]), 'value')
            return `${day} ${call}`
        },
        onChangeCourse() {
            this.formData.lesson_type_uuid = null
            this.formData.teacher_uuid = null
            this.formData.capacity_uuid = null
        },
        onChangeLessonType(uuid) {
            const lessonType = _.find(this.lessonTypes, [ 'uuid', uuid ])
            this.formData.teacher_uuid = _.get(lessonType, 'teacher.uuid', null)
            this.formData.capacity_uuid = _.get(lessonType, 'capacity_uuid', null)
        },
        onDaySelecting() {
            this.selectingDay = true
        },
        onCallSelecting() {
            this.selectingCall = true
        },
        onDaySelected() {
            this.setDates()
            this.selectingDay = false
        },
        onCallSelected() {
            this.setDates()
            this.selectingCall = false
        },
        onChangeLessonMode(value) {
            if (value === 'online') {
                this.formData.location_uuid = value
            } else {
                this.formData.location_uuid = null
            }
        },
        onSubmit(action) {
            if (this.$refs.scheduleLessonForm.validate()) {
                this.$emit('submit', this.formData, action, this.originalData)
            }
        },
        onCancel() {
            this.$emit('cancel')
        }
    }
}
</script>

<style lang="scss">
.schedule-lesson-form {
    .v-icon {
        font-size: 20px;
    }

    .v-icon__svg {
        width: 20px;
        height: 20px;
    }
}
</style>
